.carousel {
  --swiper-pagination-color: #000;
  --swiper-pagination-bullet-size: 0.625rem;
  --swiper-pagination-bullet-width: 0.625rem;
  --swiper-pagination-bullet-height: 0.625rem;
  --swiper-pagination-bullet-inactive-color: #000;
  --swiper-pagination-bullet-inactive-opacity: 0.2;
  --swiper-pagination-bullet-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 4px;
  --swiper-pagination-bullet-vertical-gap: 6px;
  width: 100%;
}

:global([data-theme="dark"]) .carousel {
  --swiper-pagination-color: #ffffff;
  --swiper-pagination-bullet-inactive-color: #EEEFE4;
}

.autoplay :global(.swiper-wrapper) {
  transition-timing-function: linear;
}

.overflowHidden :global(.swiper) {
  overflow: hidden;
}

.overflowDim :global(.swiper),
.overflowVisible :global(.swiper) {
  overflow: visible;
  position: relative;
}

.overflowDim :global(.swiper-slide) {
  opacity: 0.4;
  transition: opacity ease-in-out 0.3s;
}

.overflowDim :global(.swiper-slide-active) {
  opacity: 1;
}

.perspectiveSkewed :global(.swiper-slide) {
  transform: translateY(10%);
  transform-style: preserve-3d;
  transition: transform ease-in-out 0.3s;
}

.perspectiveSkewed :global(.swiper-slide) {
  z-index: -1;
}

.perspectiveSkewed :global(.swiper-slide-active) {
  transform: scale(1.1) translateY(10%);
  z-index: 2;
}